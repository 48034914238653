<template>
  <div class="container-fluid">
		<div class="row vh-100 flex-center no-gutters">
			<div class="col-lg-8 col-xxl-5">
				<img 
					class="bg-auth-circle-shape" 
					src="@/assets/img/illustrations/bg-shape.png" 
					alt="" width="250">
					<img 
						class="bg-auth-circle-shape-2" 
						src="@/assets/img/illustrations/shape-1.png" 
						alt="" width="150">
				<div class="card overflow-hidden z-index-1">
					<div class="card-body p-0">
						<div class="row no-gutters h-100">
							<div class="col-md-5 text-white text-center bg-card-gradient">
								<div class="position-relative p-4 pt-md-5 pb-md-7">
									<div 
										class="bg-holder bg-auth-card-shape" 
										style="background-image:url(/img/illustrations/half-circle.png);">
									</div>
									<!--/.bg-holder-->

									<div class="z-index-1 position-relative">
										<router-link class="navbar-brand fs-4 ml-4 mr-7" to="/" exact>
											<div class="d-flex align-items-center text-primary ">
                        <img 
                        class="mr-1" 
                        src="@/assets/img/illustrations/bplan-logo.png" style="filter: contrast(120%);" alt="" width="40" />
                        <span class="text-white">
                          ТВОЙ
                          <span class="text-800"
                            style="font-size: 1rem">
                            бизнес-план
                          </span> 
                        </span>
                      </div>
										</router-link>
										<p class="text-1000">
											Надежный и практичный инструмент для 
                      <strong>профессионального бизнес-планирования</strong>!
										</p>
									</div>
								</div>
								<div class="mt-3 mb-4 mt-md-4 mb-md-5">
									<p class="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
										Прочитать 
                    <a class="text-underline text-300" href="#!">условия</a> 
                    <br> и <a class="text-underline text-300" href="#!">политику конфеденциальности</a>
									</p>
								</div>
							</div>
							<div class="col-md-7 d-flex flex-center">
								<div class="p-4 p-md-5 flex-grow-1">
									<div class="text-center">
                    <img 
                      class="d-block mx-auto mb-4" 
                      src="@/assets/img/illustrations/rocket.png" 
                      alt="shield" width="70" />
										<h3 class="mb-3">До скорых встреч!</h3>
										<p> Вы успешно вышли из системы 
											<br> Надеемся Вы стали ближе к своей цели.
                    </p>
										<router-link 
                      class="btn btn-primary btn-sm mt-4" 
                      to="/login">
                      <span class="fas fa-chevron-left mr-1" data-fa-transform="shrink-4 down-1"></span>
                      Войти снова
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>

</style>